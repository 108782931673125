import { Avatar, Stack, Typography } from "@mui/material";
import defaultLogo from "./notaires.svg";
import { useLoaderData } from "@remix-run/react";

export default function () {
  const { logoUrl, officeName } = useLoaderData<{
    logoUrl?: string | null;
    officeName?: string | null;
  }>();
  return (
    <Stack alignItems="center">
      <Avatar
        variant={"square"}
        src={logoUrl || defaultLogo}
        sx={(theme) => ({
          width: theme.spacing(15),
          height: theme.spacing(15),
          backgroundColor: "transparent",
          "& img": { objectFit: "contain" },
        })}
      />
      {!logoUrl && <Typography variant="h6">{officeName}</Typography>}
    </Stack>
  );
}
